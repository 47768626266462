import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
    render() {
        return (
            <div>
                <div className="addbg">
                    <div className="wrapper produkty">
                        <header>
                            <h1 className="logo">
                                <a href="/">Neutrogena</a>
                            </h1>
                            <nav>
                                <a href="/produkty/" className="current">
                                    Produkty
                </a>
                                <span>| </span>
                                <a href="/neutrogena/o-nas/" className="link">
                                    NEUTROGENA<sup>®</sup>
                                </a>
                                <span>| </span>
                                <a href="/budte-krasna/" className="link">
                                    Buďte krásná
                </a>

                                <span />

                            </nav>
                        </header>
                        <div className="top">
                            <h2>
                                Produkty
                <br />
                NEUTROGENA <span>®</span>
                            </h2>
                            <h3>Vyberte si produkt na míru vašim potřebám.</h3>
                            <div className="breadcrumb">
                                <a href="/">
                                    NEUTROGENA<sup>®</sup>
                                </a>
                                <span>&gt;</span>
                Produkty
              </div>
                        </div>
                        <div className="content">
                            <div className="filters">
                <p className="head">
                  Filtrování
                  
                </p>
                <div className="filtrate">
                  <div className="middle">
                    <div className="aboutAnchor" />
                    <div>
                      <form
                        id="Form_ProductsFilter"
                        action="/produkty/ProductsFilter"
                        method="post"
                        encType="application/x-www-form-urlencoded"
                      >
                        <p
                          id="Form_ProductsFilter_error"
                          className="message "
                          style={{
                            display: "none"
                          }}
                        />
                        <fieldset>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice0"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left filter-title"
                                  htmlFor="Form_ProductsFilter_Nice0"
                                >
                                  TYP PRODUKTU
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice0"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val6">
                                      <label htmlFor="Form_ProductsFilter_Nice0_6" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Telova-mleka/" style={{ color: "white" }}>TĚLOVÁ MLÉKA</a></p>
                                      </label>
                                    </li>
                                    <li className="even val7">
                                      <label htmlFor="Form_ProductsFilter_Nice0_7" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Pece-o-ruce/" style={{ color: "white" }}>PÉČE O RUCE</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val8">
                                      <label htmlFor="Form_ProductsFilter_Nice0_8" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Pece-o-chodidla/" style={{ color: "white" }}>PÉČE O CHODIDLA</a></p>
                                      </label>
                                    </li>
                                    <li className="even val9">
                                      <label htmlFor="Form_ProductsFilter_Nice0_9" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Pece-o-oblicej/" style={{ color: "white" }}>PÉČE O OBLIČEJ</a></p>
                                      </label>
                                    </li>
                                    <li className="even val9">
                                      <label htmlFor="Form_ProductsFilter_Nice0_9" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Cisteni-pleti/" style={{ color: "white" }}>ČIŠTĚNÍ PLETI</a></p>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice1"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left filter-title"
                                  htmlFor="Form_ProductsFilter_Nice1"
                                >
                                  TYP POKOŽKY
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice1"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val14">
                                      <label htmlFor="Form_ProductsFilter_Nice1_14" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Velmi-sucha/" style={{ color: "white" }}>VELMI SUCHÁ</a></p>
                                      </label>
                                    </li>
                                    <li className="even val15">
                                      <label htmlFor="Form_ProductsFilter_Nice1_15" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Sucha/" style={{ color: "white" }}>SUCHÁ</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val16">
                                      <label htmlFor="Form_ProductsFilter_Nice1_16" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Normalni/" style={{ color: "white" }}>NORMÁLNÍ</a></p>
                                      </label>
                                    </li>
                                    <li className="even val17">
                                      <label htmlFor="Form_ProductsFilter_Nice1_17" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Citliva/" style={{ color: "white" }}>CITLIVÁ</a></p>
                                      </label>
                                    </li>
                                    <li className="even val17">
                                      <label htmlFor="Form_ProductsFilter_Nice1_17" className="sub-category-filter">
                                        <p className="sub-category-filter" style={{ color: "white" }}><a href="/category/Plet-s-nedokonalostmi/" style={{ color: "white" }}>PLEŤ S NEDOKONALOSTMI</a></p>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="CompositeField composite nolabel custom-filter">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice2"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left filter-title"
                                  htmlFor="Form_ProductsFilter_Nice2"
                                >
                                  PRODUKTOVÁ ŘADA
                                </label>
                                <div className="middleColumn cus-filter">
                                  <ul
                                    id="Form_ProductsFilter_Nice2"
                                    className="optionset checkboxset cus-filter-1"
                                  >
                                    <li className="odd val18">
                                      <label htmlFor="Form_ProductsFilter_Nice2_18" >
                                        <p className="sub-category-filter"><a href="/category/Hydro-boost/" style={{ color: "white" }}>HYDRO BOOST<sup>®</sup></a></p>
                                      </label>
                                    </li>
                                    <li className="even val19">
                                      <label htmlFor="Form_ProductsFilter_Nice2_19" >
                                        <p className="sub-category-filter"><a href="/category/Curcuma-clear/" style={{ color: "white" }}>CURCUMA CLEAR</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Skin-detox/" style={{ color: "white" }}>SKIN DETOX</a></p>
                                      </label>
                                    </li>
                                    
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Bright-boost/" style={{ color: "white" }}>BRIGHT BOOST</a></p>
                                      </label>
                                    </li>
									<li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Retinol-Boost/" style={{ color: "white" }}>RETINOL BOOST</a></p>
                                      </label>
                                    </li>
                                  </ul>
                                  <ul
                                    id="Form_ProductsFilter_Nice2"
                                    className="optionset checkboxset cus-filter-1"
                                  >  
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Anti-akne/" style={{ color: "white" }}>ANTI-AKNE</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/Norska-receptura/" style={{ color: "white" }}>NORSKÁ RECEPTURA</a></p>
                                      </label>
                                    </li>
                                    <li className="odd val20">
                                      <label htmlFor="Form_ProductsFilter_Nice2_20">
                                        <p className="sub-category-filter"><a href="/category/T-gel/" style={{ color: "white" }}>T-GEL<sup>®</sup></a></p>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <input
                            type="hidden"
                            name="SecurityID"
                            defaultValue="e2d20bf1c48b0a151eee0c23b91b10b61f8677e0"
                            className="hidden"
                            id="Form_ProductsFilter_SecurityID"
                          />
                          <div className="clear" />
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
                            <div className="products" >
                            <div className="row">
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/156/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA_Retinol_Boost_Day_Cream_Box+Bottle.png"
                      alt="NEUTROGENA® Retinol Boost Denní krém SPF 15"
                    />
                    <span className="txt">
                      <p>
                      NEUTROGENA<sup>®</sup><br/>Retinol Boost<br/>Denní krém SPF 15 
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/157/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA_Retinol_Boost_Night_Cream_Box+Bottle.png"
                      alt="NEUTROGENA® Retinol Boost Noční krém"
                    />
                    <span className="txt">
                      <p>
                      NEUTROGENA<sup>®</sup><br/>Retinol Boost<br/>Noční krém
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/159/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA_Retinol_Boost_Serum_Box+Tube.png"
                      alt="NEUTROGENA® Retinol Boost Sérum"
                    />
                    <span className="txt">
                      <p>
                      NEUTROGENA<sup>®</sup><br/>Retinol Boost<br/>Sérum
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/158/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA_Retinol_Boost_Eye_Cream_Box+Tube.png"
                      alt="NEUTROGENA® Retinol Boost Oční krém"
                    />
                    <span className="txt">
                      <p>
                      NEUTROGENA<sup>®</sup><br/>Retinol Boost<br/>Oční krém 
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/146/"
                  >
                    <img
                      src="/assets/Uploads/NTG-BrightBoost-GelCream_1000x1000px.png"
                      alt="NEUTROGENA® Bright Boost Rozjasňující gelový krém"
                    />
                    <span className="txt">
                      <p>
                      NEUTROGENA<sup>®</sup><br/>Bright Boost<br/>Rozjasňující gelový krém 
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                </div>
                            <div className="row">
                  {/*<a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/146/"
                  >
                    <img
                      src="/assets/Uploads/NTG_3574661591575_EMEA_Bright_Boost_Gel_Cream_CEE_50ML_000.png"
                      alt="NEUTROGENA® Bright Boost Rozjasňující gelový krém"
                    />
                    <span className="txt">
                      <p>
                      NEUTROGENA<sup>®</sup><br/>Bright Boost<br/>Rozjasňující gelový krém 
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a> */}
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/147/"
                  >
                    <img
                      src="/assets/Uploads/NTG-BrightBoost-GelSPF_500x1000px.png"
                      alt="NEUTROGENA® Bright Boost Rozjasňující pleťový gel s SPF 30"
                    />
                    <span className="txt">
                      <p>
                      NEUTROGENA<sup>®</sup><br/>Bright Boost<br/>Rozjasňující pleťový gel s SPF 30
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/148/"
                  >
                    <img
                      src="/assets/Uploads/NTG-BrightBoost-NightCream_1000x1000px.png"
                      alt="NEUTROGENA® Bright Boost Rozjasňující noční krém"
                    />
                    <span className="txt">
                      <p>
                      NEUTROGENA<sup>®</sup><br/>Bright Boost<br/>Rozjasňující noční krém 
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/149/"
                  >
                    <img
                      src="/assets/Uploads/NTG-BrightBoost-Serum_500x1000px.png"
                      alt="NEUTROGENA® Bright Boost Rozjasňující sérum"
                    />
                    <span className="txt">
                      <p>
                      NEUTROGENA<sup>®</sup><br/>Bright Boost<br/>Rozjasňující sérum
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/product/150/"
                  >
                    <img
                      src="/assets/Uploads/NTG-BrightBoost-Peeling_500x1000px.png"
                      alt="NTG Bright Boost Rozjasňující pleťový peeling"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup><br/>Bright Boost<br/>Rozjasňující pleťový peeling
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/42/"
                                    >
                                        <img
                                            src="/assets/Uploads/ntg-mléko-tělové-citlivá-pleť-400-ml_3574660527452_optimized_optimized.png"
                                            alt="NEUTROGENA Norská receptura Hloubkově hydratační tělové mléko bez parfemace"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Hloubkově hydratační tělové mléko bez parfemace
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                </div>
                                <div className="row">
                                    {/*<a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/42/"
                                    >
                                        <img
                                            src="/assets/Uploads/ntg-mléko-tělové-citlivá-pleť-400-ml_3574660527452_optimized_optimized.png"
                                            alt="NEUTROGENA Norská receptura Hloubkově hydratační tělové mléko bez parfemace"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Hloubkově hydratační tělové mléko bez parfemace
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a> */}
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/43/"
                                    >
                                        <img
                                            src="/assets/Uploads/ntg-mléko-telové-creamy-oil-400-ml_3574660653151_cz_optimized_optimized.jpg"
                                            alt="NTG BL 400ml creamy oil NEW"
                                        />
                                        <span className="txt">
                                            <p className="TableParagraph">
                                                NEUTROGENA<sup>®</sup> Norská receptura Hloubkově hydratační tělové mléko s olejem
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/48/"
                                    >
                                        <img
                                            src="/assets/Uploads/ntg-krém-na-suché-ruce-75-ml_3574661527925_optimized_optimized.png"
                                            alt="NTG HandCream 50ml parfemovany"
                                        />
                                        <span className="txt">
                                            <p>NEUTROGENA<sup>®</sup> Norská receptura Krém na ruce parfémovaný</p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/49/"
                                    >
                                        <img
                                            src="/assets/Uploads/ntg-krém-na-ruce-neparfemovaný-75-ml_3574661527918_optimized_optimized.png"
                                            alt="NTG HandCream 50ml unscented"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Krém na ruce neparfémovaný
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/55/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG-Lipp-LSF4-48g-bezblistru2.png"
                                            alt="NTG Lipp LSF4 48g bezblistru2"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Balzám na rty SPF 4
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/56/"
                                    >
                                        <img
                                            src="/assets/Uploads/3574660271096_Neutrogena tycinka na rty SPF20 (1) (1) (1).png"
                                            alt="Nutrogena LSF 20 SUN"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Balzám na rty SPF 20
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                </div>
                                <div className="row">
                                    {/*<a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/56/"
                                    >
                                        <img
                                            src="/assets/Uploads/3574660271096_Neutrogena tycinka na rty SPF20 (1) (1) (1).png"
                                            alt="Nutrogena LSF 20 SUN"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Balzám na rty SPF 20
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a> */}
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/59/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG-BodyBalm-FastAbsorbing_300ml_1000x1000px.png"
                                            alt="comfort balm 200ML"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Hloubkově hydratační balzám
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/62/"
                                    >
                                        <img
                                            src="/assets/Uploads/ntg-mléko-tělové-suchá-pleť-400-ml_3574660527810_optimized_optimized.png"
                                            alt="NTG body lotion 250ml suchapokozka"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Hloubkově hydratační tělové mléko
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/63/"
                                    >
                                        <img
                                            src="/assets/Uploads/ntg_3574660342338_ng_nf_hnd_nail_cr_l953_75ml_000_optimized_optimized.png"
                                            alt="NTG HandCream 75ml HandNail selection"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Krém na ruce a nehty
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/154/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG-HB-Serum-1000x1000px2.png"
                                            alt="neutrogena Koncentrované sérum kyseliny hyaluronové"
                                        />
                                        <span className="txt">
                                            <p class="TableParagraph">
                                            NEUTROGENA<sup>®</sup><br/>Hydro Boost<sup>®</sup><br/>Koncentrované sérum kyseliny hyaluronové 
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/86/"
                                    >
                                        <img
                                            src="/assets/Uploads/NEUTROGENA-HydroBoost-Body-gel-250-200x600.png"
                                            alt="NEUTROGENA HydroBoost Body gel 250 200x600"
                                        />
                                        <span className="txt">
                                            <span>NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>® </sup>Ultrahydratační tělový krém</span>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                    </a>
                                    </div>
                                    <div className="row">
                                    {/*<a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/86/"
                                    >
                                        <img
                                            src="/assets/Uploads/NEUTROGENA-HydroBoost-Body-gel-250-200x600.png"
                                            alt="NEUTROGENA HydroBoost Body gel 250 200x600"
                                        />
                                        <span className="txt">
                                            <span>NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>® </sup>Ultrahydratační tělový krém</span>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                    </a> */}
                                
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/101/"
                                    >
                                        <img
                                            src="/assets/Uploads/NeutrogenaHydroBoost-BodyGelSpray-200ml.png"
                                            alt="NeutrogenaHydroBoost BodyGelSpray 200ml"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup> Tělový hydratační sprej
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/53/"
                                    >
                                        <img
                                            src="/assets/Uploads/NTG-FC-Kremnasuchachodidla_100ml_500x1000px.png"
                                            alt="NTG Krem na sucha chodidla 50ml tuba"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>®</sup> Norská receptura Výživný krém na chodidla
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/93/"
                                    >
                                        <img
                                            src="/assets/Uploads/3574661533544_Neutrogena Hydro Boost Gel_Cream_50 ml_000.jpg"
                                            alt="NEUTROGENA HydroBoost hydratacni gelovy krem 50ml 500x"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup> Hydratační gelový krém - suchá pleť
                      </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                    </a>
                                    
                                    <a
                                        className="product group1_2 group2_2 group3_2"
                                        href="/product/139/"
                                    >
                                        <img
                                            src="/assets/Uploads/NeutrogenaHB-SkinRescureBalm.png"
                                            alt="neutrogena serum"
                                        />
                                        <span className="txt">
                                            <p>
                                                NEUTROGENA<sup>® </sup>Hydro Boost<sup>® </sup>Koncentrovaný pleťový balzám
                                            </p>
                                        </span>
                                        <span className="button">Podrobnosti</span>
                                        <span className="border" />
                                      
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="fb_3"></div>
                    </div>
                </div>
                <Footer></Footer>
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
                    }}
                />
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            "\nvar address = ' /produkty/ ';\n$(\"#Form_ProductsFilter_action_ClearAction\").click(function() {\n$('input').attr('checked', false);\n$('label.checked').attr('class', '');\nwindow.location = (address);\n});\n$(\"#allproducts\").click(function() {\nwindow.location = (address);\n});\n"
                    }}
                />
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
                    }}
                />

            </div >
        );
    }
}

export default Page;
